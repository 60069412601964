import React from 'react';
import Layout from '../components/Layout';

export default function AboutPage() {
  return (
    <>
      <Layout>
        <p>Hey! I'm the brands page.</p>
      </Layout>
    </>
  );
}
